@font-face {
  font-family: "Dubai";
  src: url("../fonts/dubai/Dubai-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Dubai-light";
  src: url("../fonts/dubai/Dubai-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Dubai-med";
  src: url("../fonts/dubai/Dubai-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Dubai-bold";
  src: url("../fonts/dubai/Dubai-Bold.ttf") format("truetype");
}

@import "app";
@import "app/root";

body {
  font-family: "Dubai";
  overflow-x: hidden;
}

.text-primary {
  font-family: "Dubai" !important;
}

p {
  color: #4a5a6b;
  font-family: "Dubai-med";
}

a {
  text-decoration: none !important;
}

img.about-sec-img {
  max-width: 100%;
  width: auto;
}

.form-control:focus,
.form-select:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #d1e2f5 !important;
  outline: 0;
  box-shadow: none;
}

.form-control,
.form-select {
  padding-top: 15px;
  padding-bottom: 15px;
  border-color: #cee0ec;
  border-radius: 20px;
}

.btn.btn-primary {
  background-color: $primary-main;
  padding: 12px;
  min-width: 150px;
  position: relative;
  cursor: pointer !important;
  border: 0 !important;
  border-radius: 25px;

  span {
    position: absolute;
    top: calc(50% - 12px);
    inset-inline-end: 12px;
    transform: scaleX(-1);

    img {
      width: 15px;
    }
  }

  &:hover,
  &:focus {
    background-color: #015f99 !important;
    border: 0 !important;
    cursor: pointer !important;
  }
}

.text-primary {
  color: $picton-blue !important;
}

.text-secondary {
  color: rgb(255 242 0) !important;
}

.fs-16 {
  font-size: 16px;
}

.underline-none {
  text-decoration: none;
}

.iti {
  width: 100% !important;
}

.lang-box {
  text-align: end;
  padding: 10px 20px 0 20px;

  .lang {
    gap: 5px;
    font-size: 14px;
    cursor: pointer !important;
    font-family: "helvetica neue";
    color: #0165a4;
    text-decoration: none;

    span {
      padding-inline-end: 5px;
    }
  }
}

.po-cursor {
  cursor: pointer;
}

.text-yellow {
  color: #fff235 !important;
}

.bg-blue-light {
  background-color: $picton-blue;
}

.bg-very-blue-light {
  background-color: #d5dfe4;
}

.shadow-bottom-light {
  box-shadow: rgba(206, 224, 236, 0.22) 0px 10px 15px -12px,
    rgba(206, 224, 236, 0.09) 0px 30px 18px -14px;
}

.btn-small {
  padding: 5px 9px !important;
  min-width: unset !important;
  border-radius: 11px !important;
}

.p-tabview-nav {
  justify-content: center;
  font-family: "Dubai";

  .p-tabview-nav-link {
    span {
      color: #0b212d !important;
      padding: 20px 20px 0 20px !important;
      font-family: "Dubai";
    }

    &:hover {
      border-bottom: 0;

      span {
        color: #0165a4 !important;
      }
    }
  }

  .p-highlight {
    .p-tabview-nav-link {
      background: #ffffff;
      border-color: #0165a4;
      border-bottom: 2px solid #0165a4;

      span {
        color: #0165a4 !important;
      }
    }
  }
}

.home-label {
  color: $picton-blue;
  font-size: 20px;
  position: relative;
  z-index: 4;
  font-family: "Dubai-med";
}

[dir="rtl"] {
  .flip-horizontally {
    transform: scaleX(-1);
  }

  .text-start {
    text-align: right !important;
  }

  .btn.btn-primary {
    span {
      transform: scaleX(-1);
    }
  }

  .about-section .about-sec-img {
    transform: scaleX(-1);
  }

  .form-select {
    padding-right: 0.75rem;
    padding-left: 2.25rem;
    background-position: left 0.75rem center;
  }

  .form-check .form-check-input.accept {
    float: right;
    margin-right: -1.5em;
    margin-left: unset;
  }

  .whyus-title {
    &::before {
      content: "";
      right: 30%;
      top: -40px;
    }
  }
}

main[dir="ltr"] {
  .btn.btn-primary {
    span {
      img {
        transform: scaleX(-1);
      }
    }
  }

  .dropdown-menu {
    left: unset;
    right: 0;
  }
}

.modal {
  .modal-header {
    background-color: $picton-blue;
    color: #fff;
    position: absolute;
    width: 110%;
    height: 150px;
    top: -80px;
    z-index: -1;
    right: -5%;
    border-radius: 30px;
    align-items: flex-start;
    justify-content: center;

    .btn-close {
      position: absolute;
      top: 20px;
      left: 12px;
      color: #fff;
      --bs-btn-close-bg: url("../images/icons/exit-white.svg");
      --bs-btn-close-opacity: 1;
      background-size: 25px;
    }

    .modal-title {
      margin-top: 10px;
    }
  }

  .modal-content {
    border-radius: 30px;
    margin-top: 80px;
  }
}

.p-tabview-nav-container {
  margin-top: 20px;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.cursor-pointer {
  cursor: pointer !important;

  &:hover {
    cursor: pointer !important;
  }
}

.modal-lg-custom {
  max-width: 50% !important;
}

.preloader svg {
  width: 100%;
  height: auto;
}

.preloader .path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  stroke: black;
  fill-opacity: 0;
  animation: dash 1s ease-in-out forwards, fill 1s ease-in-out forwards 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-duration: 3s;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes fill {
  to {
    fill-opacity: 1;
  }
}

main[dir="rtl"] {
  input[type="tel"] {
    text-align: right;
    direction: ltr;
  }

  input[type="tel"]:-moz-placeholder {
    text-align: right;
  }

  input[type="tel"]:-ms-input-placeholder {
    text-align: right;
  }

  input[type="tel"]::-webkit-input-placeholder {
    text-align: right;
  }
}

main[dir="rtl"] {
  .otp-input[type="tel"] {
    text-align: center;
    direction: ltr;
  }

  .otp-input[type="tel"]:-moz-placeholder {
    text-align: center;
  }

  .otp-input[type="tel"]:-ms-input-placeholder {
    text-align: center;
  }

  .otp-input[type="tel"]::-webkit-input-placeholder {
    text-align: center;
  }
}

.cancleBtn {
  color: #047ac2;

  &:hover {
    cursor: pointer !important;
  }
}

.gray-btn {
  background-color: #dbe3e8 !important;
  color: #949398;

  &:hover {
    color: #fff;
  }
}

.payment-note {
  background-color: #f2f7fa !important;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;

  h5 {
    font-size: 18px !important;
  }

  span {
    font-weight: 700;
  }
}

.ltr {
  direction: ltr !important;
}

@media (min-width: 776.98px) {
  .modal.show .modal-dialog {
    max-width: 60%;
    width: max-content;
  }
}
.calendar {
  padding: 0px;
}

.calendar .p-calendar {
  width: 100%;
  padding: 6px;
}
.calendar .p-calendar input {
  border: 0 !important;
}
.calendar .p-calendar input:focus {
  box-shadow: unset !important;
  outline: unset !important;
}
