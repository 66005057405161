

.me-2 {
  margin-inline-end: 0.5rem;
}
.cursor-pointer {
  cursor: pointer;
}

.unicode-plainText {
  unicode-bidi: plaintext;
}

.word-break {
  word-break: break-word;
}

.rtl {
  &.rtl-icon,
  .rtl-icon {
    transform: rotateY(180deg);
  }
}
