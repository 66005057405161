// color variables

:root {
    --msn-primary-main: #047AC2;
    --msn-primary-dark: #0B212D;
    --msn-primary-light: #E6F1FF;

    --msn-secondary-main: #29cc8b;
    --msn-secondary-dark: #21BF80;
    --msn-secondary-light: #F1FDF4;

    --msn-white: #fff;
    --msn-grey-main: #4A5A6B;
    --msn-grey-light: #f5f8fa;
    --msn-black: #000;

    --msn-success: #29cc8b;
    --msn-error: #f93d3d;
    --msn-error-light: #FEE2E2;
    --msn-error-lightness-90: #FDCECE;
    --msn-error-lightness-86: #FDBABA;
    --msn-orange: #FDAA3E;
    --msn-orange-light: #FFEBD1;

    --msn-picton-blue: #0065A4;
    --msn-picton-blue-light: #F1FAFF;
    --msn-texas-rose: #FFBC4F;
    --msn-texas-rose-light: #FFF5E5;
    --msn-mauve: #B898FE;
    --msn-mauve-light: #F9F7FF;

    --msn-font-style-main: #173E67;
    --msn-font-style-secondary: #5D758E;
    --msn-font-style-placeholder: #C3C6C9;

    --msn-bg-ice-blue: #F7FBFF;
    --msn-bg-cloud: #FAFBFC;
    --msn-bg-overlay: rgba(0, 0, 0, 0.7);

    --msn-border-color-main: #D0D9E2;
    --msn-border-color-light: #EFF3F6;

    --msn-gradients-green: linear-gradient(237.68deg, #7BE9B8 0%, #52BC8D 100%);
    --msn-gradients-blue-green: linear-gradient(237.68deg, #65D3A1 0.52%, #4BB3EF 53.13%, #6176EF 100%);
    --msn-gradients-blue: linear-gradient(259.27deg, #92BEF8 0%, #5989EF 100%);

    // Shadows
    --msn-shadow-main: #0000001a;
    --msn-box-shadow-normal-color: rgba(20, 50, 80, 0.12);
    --msn-box-shadow-normal: box-shadow: 0px 2px 35px 0px var(--msn-shadow-main);

   // padding
   --xSmall: 5px;
   --small : 10px;
   --medium: 15px;
   --large: 20px;
   --xLarge:30px;
--xxLarge:50px;


}
