$primary-main: var(--msn-primary-main);
$primary-dark: var(--msn-primary-dark);
$primary-light: var(--msn-primary-light);

$secondary-main: var(--msn-secondary-main);
$secondary-dark: var(--msn-secondary-dark);
$secondary-light: var(--msn-secondary-light);

$white: var(--msn-white);
$grey-main: var(--msn-grey-main);
$grey-light: var(--msn-grey-light);
$black: var(--msn-black);

$success: var(--msn-success);
$success-light: var(--msn-secondary-light);
$error: var(--msn-error);
$error-light: var(--msn-error-light);
$error-lightness-90: var(--msn-error-lightness-90);
$error-lightness-86: var(--msn-error-lightness-86);
$orange: var(--msn-orange);
$orange-light: var(--msn-orange-light);

$picton-blue: var(--msn-picton-blue);
$picton-blue-light: var(--msn-picton-blue-light);
$texas-rose: var(--msn-texas-rose);
$texas-rose-light: var(--msn-texas-rose-light);
$mauve: var(--msn-mauve);
$mauve-light: var(--msn-mauve-light);

$font-style-main: var(--msn-font-style-main);
$font-style-secondary: var(--msn-font-style-secondary);
$font-style-placeholder: var(--msn-font-style-placeholder);

$bg-ice-blue: var(--msn-bg-ice-blue);
$bg-cloud: var(--msn-bg-cloud);
$bg-overlay: var(--msn-bg-overlay);

$border-color-main: var(--msn-border-color-main);
$border-color-light: var(--msn-border-color-light);

$gradients-green: var(--msn-gradients-green);
$gradients-blue-green: var(--msn-gradients-blue-green);
$gradients-blue: var(--msn-gradients-blue);

// Shadows
$box-shadow-normal-color: var(--msn-box-shadow-normal-color);
$box-shadow-normal: var(--msn-box-shadow-normal);
