@font-face {
  font-family: 'check-plus';
  src:  url('fonts/check-plus.eot?ag641n');
  src:  url('fonts/check-plus.eot?ag641n#iefix') format('embedded-opentype'),
    url('fonts/check-plus.ttf?ag641n') format('truetype'),
    url('fonts/check-plus.woff?ag641n') format('woff'),
    url('fonts/check-plus.svg?ag641n#check-plus') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="ci-"], [class*=" ci-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'check-plus' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ci-home:before {
  content: "\e99e";
}
.ci-home2:before {
  content: "\e99f";
}
.ci-home3:before {
  content: "\e9a0";
}
.ci-office:before {
  content: "\e9a1";
}
.ci-newspaper:before {
  content: "\e9a2";
}
.ci-pencil:before {
  content: "\e9a3";
}
.ci-pencil2:before {
  content: "\e9a4";
}
.ci-quill:before {
  content: "\e9a5";
}
.ci-pen:before {
  content: "\e9a6";
}
.ci-blog:before {
  content: "\e9a7";
}
.ci-eyedropper:before {
  content: "\e9a8";
}
.ci-droplet:before {
  content: "\e9a9";
}
.ci-paint-format:before {
  content: "\e9aa";
}
.ci-image:before {
  content: "\e9ab";
}
.ci-images:before {
  content: "\e9ac";
}
.ci-camera:before {
  content: "\e9ad";
}
.ci-headphones:before {
  content: "\e9ae";
}
.ci-music:before {
  content: "\e9af";
}
.ci-play:before {
  content: "\e9b0";
}
.ci-film:before {
  content: "\e9b1";
}
.ci-video-camera:before {
  content: "\e9b2";
}
.ci-dice:before {
  content: "\e9b3";
}
.ci-pacman:before {
  content: "\e9b4";
}
.ci-spades:before {
  content: "\e9b5";
}
.ci-clubs:before {
  content: "\e9b6";
}
.ci-diamonds:before {
  content: "\e9b7";
}
.ci-bullhorn:before {
  content: "\e9b8";
}
.ci-connection:before {
  content: "\e9b9";
}
.ci-podcast:before {
  content: "\e9ba";
}
.ci-feed:before {
  content: "\e9bb";
}
.ci-mic:before {
  content: "\e9bc";
}
.ci-book:before {
  content: "\e9bd";
}
.ci-books:before {
  content: "\e9be";
}
.ci-library:before {
  content: "\e9bf";
}
.ci-file-text:before {
  content: "\e9c0";
}
.ci-profile:before {
  content: "\e9c1";
}
.ci-file-empty:before {
  content: "\e9c2";
}
.ci-files-empty:before {
  content: "\e9c3";
}
.ci-file-text2:before {
  content: "\e9c4";
}
.ci-file-picture:before {
  content: "\e9c5";
}
.ci-file-music:before {
  content: "\e9c6";
}
.ci-file-play:before {
  content: "\e9c7";
}
.ci-file-video:before {
  content: "\e9c8";
}
.ci-file-zip:before {
  content: "\e9c9";
}
.ci-copy:before {
  content: "\e9ca";
}
.ci-paste:before {
  content: "\e9cb";
}
.ci-stack:before {
  content: "\e9cc";
}
.ci-folder:before {
  content: "\e9cd";
}
.ci-folder-open:before {
  content: "\e9ce";
}
.ci-folder-plus:before {
  content: "\e9cf";
}
.ci-folder-minus:before {
  content: "\e9d0";
}
.ci-folder-download:before {
  content: "\e9d1";
}
.ci-folder-upload:before {
  content: "\e9d2";
}
.ci-price-tag:before {
  content: "\e9d3";
}
.ci-price-tags:before {
  content: "\e9d4";
}
.ci-barcode:before {
  content: "\e9d5";
}
.ci-qrcode:before {
  content: "\e9d6";
}
.ci-ticket:before {
  content: "\e9d7";
}
.ci-cart:before {
  content: "\e9d8";
}
.ci-coin-dollar:before {
  content: "\e9d9";
}
.ci-coin-euro:before {
  content: "\e9da";
}
.ci-coin-pound:before {
  content: "\e9db";
}
.ci-coin-yen:before {
  content: "\e9dc";
}
.ci-credit-card:before {
  content: "\e9dd";
}
.ci-calculator:before {
  content: "\e9de";
}
.ci-lifebuoy:before {
  content: "\e9df";
}
.ci-phone:before {
  content: "\e9e0";
}
.ci-phone-hang-up:before {
  content: "\e9e1";
}
.ci-address-book:before {
  content: "\e9e2";
}
.ci-envelop:before {
  content: "\e9e3";
}
.ci-pushpin:before {
  content: "\e9e4";
}
.ci-location:before {
  content: "\e9e5";
}
.ci-location2:before {
  content: "\e9e6";
}
.ci-compass:before {
  content: "\e9e7";
}
.ci-compass2:before {
  content: "\e9e8";
}
.ci-map:before {
  content: "\e9e9";
}
.ci-map2:before {
  content: "\e9ea";
}
.ci-history:before {
  content: "\e9eb";
}
.ci-clock:before {
  content: "\e9ec";
}
.ci-clock2:before {
  content: "\e9ed";
}
.ci-alarm:before {
  content: "\e9ee";
}
.ci-bell:before {
  content: "\e9ef";
}
.ci-stopwatch:before {
  content: "\e9f0";
}
.ci-calendar:before {
  content: "\e9f1";
}
.ci-printer:before {
  content: "\e9f2";
}
.ci-keyboard:before {
  content: "\e9f3";
}
.ci-display:before {
  content: "\e9f4";
}
.ci-laptop:before {
  content: "\e9f5";
}
.ci-mobile:before {
  content: "\e9f6";
}
.ci-mobile2:before {
  content: "\e9f7";
}
.ci-tablet:before {
  content: "\e9f8";
}
.ci-tv:before {
  content: "\e9f9";
}
.ci-drawer:before {
  content: "\e9fa";
}
.ci-drawer2:before {
  content: "\e9fb";
}
.ci-box-add:before {
  content: "\e9fc";
}
.ci-box-remove:before {
  content: "\e9fd";
}
.ci-download:before {
  content: "\e9fe";
}
.ci-upload:before {
  content: "\e9ff";
}
.ci-floppy-disk:before {
  content: "\ea00";
}
.ci-drive:before {
  content: "\ea01";
}
.ci-database:before {
  content: "\ea02";
}
.ci-undo:before {
  content: "\ea03";
}
.ci-redo:before {
  content: "\ea04";
}
.ci-undo2:before {
  content: "\ea05";
}
.ci-redo2:before {
  content: "\ea06";
}
.ci-forward:before {
  content: "\ea07";
}
.ci-reply:before {
  content: "\ea08";
}
.ci-bubble:before {
  content: "\ea09";
}
.ci-bubbles:before {
  content: "\ea0a";
}
.ci-bubbles2:before {
  content: "\ea0b";
}
.ci-bubble2:before {
  content: "\ea0c";
}
.ci-bubbles3:before {
  content: "\ea0d";
}
.ci-bubbles4:before {
  content: "\ea0e";
}
.ci-user:before {
  content: "\ea0f";
}
.ci-users:before {
  content: "\ea10";
}
.ci-user-plus:before {
  content: "\ea11";
}
.ci-user-minus:before {
  content: "\ea12";
}
.ci-user-check:before {
  content: "\ea13";
}
.ci-user-tie:before {
  content: "\ea14";
}
.ci-quotes-left:before {
  content: "\ea15";
}
.ci-quotes-right:before {
  content: "\ea16";
}
.ci-hour-glass:before {
  content: "\ea17";
}
.ci-spinner:before {
  content: "\ea18";
}
.ci-spinner2:before {
  content: "\ea19";
}
.ci-spinner3:before {
  content: "\ea1a";
}
.ci-spinner4:before {
  content: "\ea1b";
}
.ci-spinner5:before {
  content: "\ea1c";
}
.ci-spinner6:before {
  content: "\ea1d";
}
.ci-spinner7:before {
  content: "\ea1e";
}
.ci-spinner8:before {
  content: "\ea1f";
}
.ci-spinner9:before {
  content: "\ea20";
}
.ci-spinner10:before {
  content: "\ea21";
}
.ci-spinner11:before {
  content: "\ea22";
}
.ci-binoculars:before {
  content: "\ea23";
}
.ci-search:before {
  content: "\ea24";
}
.ci-zoom-in:before {
  content: "\ea25";
}
.ci-zoom-out:before {
  content: "\ea26";
}
.ci-enlarge:before {
  content: "\ea27";
}
.ci-shrink:before {
  content: "\ea28";
}
.ci-enlarge2:before {
  content: "\ea29";
}
.ci-shrink2:before {
  content: "\ea2a";
}
.ci-key:before {
  content: "\ea2b";
}
.ci-key2:before {
  content: "\ea2c";
}
.ci-lock:before {
  content: "\ea2d";
}
.ci-unlocked:before {
  content: "\ea2e";
}
.ci-wrench:before {
  content: "\ea2f";
}
.ci-equalizer:before {
  content: "\ea30";
}
.ci-equalizer2:before {
  content: "\ea31";
}
.ci-cog:before {
  content: "\ea32";
}
.ci-cogs:before {
  content: "\ea33";
}
.ci-hammer:before {
  content: "\ea34";
}
.ci-magic-wand:before {
  content: "\ea35";
}
.ci-aid-kit:before {
  content: "\ea36";
}
.ci-bug:before {
  content: "\ea37";
}
.ci-pie-chart:before {
  content: "\ea38";
}
.ci-stats-dots:before {
  content: "\ea39";
}
.ci-stats-bars:before {
  content: "\ea3a";
}
.ci-stats-bars2:before {
  content: "\ea3b";
}
.ci-trophy:before {
  content: "\ea3c";
}
.ci-gift:before {
  content: "\ea3d";
}
.ci-glass:before {
  content: "\ea3e";
}
.ci-glass2:before {
  content: "\ea3f";
}
.ci-mug:before {
  content: "\ea40";
}
.ci-spoon-knife:before {
  content: "\ea41";
}
.ci-leaf:before {
  content: "\ea42";
}
.ci-rocket:before {
  content: "\ea43";
}
.ci-meter:before {
  content: "\ea44";
}
.ci-meter2:before {
  content: "\ea45";
}
.ci-hammer2:before {
  content: "\ea46";
}
.ci-fire:before {
  content: "\ea47";
}
.ci-lab:before {
  content: "\ea48";
}
.ci-magnet:before {
  content: "\ea49";
}
.ci-bin:before {
  content: "\ea4a";
}
.ci-bin2:before {
  content: "\ea4b";
}
.ci-briefcase:before {
  content: "\ea4c";
}
.ci-airplane:before {
  content: "\ea4d";
}
.ci-truck:before {
  content: "\ea4e";
}
.ci-road:before {
  content: "\ea4f";
}
.ci-accessibility:before {
  content: "\ea50";
}
.ci-target:before {
  content: "\ea51";
}
.ci-shield:before {
  content: "\ea52";
}
.ci-power:before {
  content: "\ea53";
}
.ci-switch:before {
  content: "\ea54";
}
.ci-power-cord:before {
  content: "\ea55";
}
.ci-clipboard:before {
  content: "\ea56";
}
.ci-list-numbered:before {
  content: "\ea57";
}
.ci-list:before {
  content: "\ea58";
}
.ci-list2:before {
  content: "\ea59";
}
.ci-tree:before {
  content: "\ea5a";
}
.ci-menu:before {
  content: "\ea5b";
}
.ci-menu2:before {
  content: "\ea5c";
}
.ci-menu3:before {
  content: "\ea5d";
}
.ci-menu4:before {
  content: "\ea5e";
}
.ci-cloud:before {
  content: "\ea5f";
}
.ci-cloud-download:before {
  content: "\ea60";
}
.ci-cloud-upload:before {
  content: "\ea61";
}
.ci-cloud-check:before {
  content: "\ea62";
}
.ci-download2:before {
  content: "\ea63";
}
.ci-upload2:before {
  content: "\ea64";
}
.ci-download3:before {
  content: "\ea65";
}
.ci-upload3:before {
  content: "\ea66";
}
.ci-sphere:before {
  content: "\ea67";
}
.ci-earth:before {
  content: "\ea68";
}
.ci-link:before {
  content: "\ea69";
}
.ci-flag:before {
  content: "\ea6a";
}
.ci-attachment:before {
  content: "\ea6b";
}
.ci-eye:before {
  content: "\ea6c";
}
.ci-eye-plus:before {
  content: "\ea6d";
}
.ci-eye-minus:before {
  content: "\ea6e";
}
.ci-eye-blocked:before {
  content: "\ea6f";
}
.ci-bookmark:before {
  content: "\ea70";
}
.ci-bookmarks:before {
  content: "\ea71";
}
.ci-sun:before {
  content: "\ea72";
}
.ci-contrast:before {
  content: "\ea73";
}
.ci-brightness-contrast:before {
  content: "\ea74";
}
.ci-star-empty:before {
  content: "\ea75";
}
.ci-star-half:before {
  content: "\ea76";
}
.ci-star-full:before {
  content: "\ea77";
}
.ci-heart:before {
  content: "\ea78";
}
.ci-heart-broken:before {
  content: "\ea79";
}
.ci-man:before {
  content: "\ea7a";
}
.ci-woman:before {
  content: "\ea7b";
}
.ci-man-woman:before {
  content: "\ea7c";
}
.ci-happy:before {
  content: "\ea7d";
}
.ci-happy2:before {
  content: "\ea7e";
}
.ci-smile:before {
  content: "\ea7f";
}
.ci-smile2:before {
  content: "\ea80";
}
.ci-tongue:before {
  content: "\ea81";
}
.ci-tongue2:before {
  content: "\ea82";
}
.ci-sad:before {
  content: "\ea83";
}
.ci-sad2:before {
  content: "\ea84";
}
.ci-wink:before {
  content: "\ea85";
}
.ci-wink2:before {
  content: "\ea86";
}
.ci-grin:before {
  content: "\ea87";
}
.ci-grin2:before {
  content: "\ea88";
}
.ci-cool:before {
  content: "\ea89";
}
.ci-cool2:before {
  content: "\ea8a";
}
.ci-angry:before {
  content: "\ea8b";
}
.ci-angry2:before {
  content: "\ea8c";
}
.ci-evil:before {
  content: "\ea8d";
}
.ci-evil2:before {
  content: "\ea8e";
}
.ci-shocked:before {
  content: "\ea8f";
}
.ci-shocked2:before {
  content: "\ea90";
}
.ci-baffled:before {
  content: "\ea91";
}
.ci-baffled2:before {
  content: "\ea92";
}
.ci-confused:before {
  content: "\ea93";
}
.ci-confused2:before {
  content: "\ea94";
}
.ci-neutral:before {
  content: "\ea95";
}
.ci-neutral2:before {
  content: "\ea96";
}
.ci-hipster:before {
  content: "\ea97";
}
.ci-hipster2:before {
  content: "\ea98";
}
.ci-wondering:before {
  content: "\ea99";
}
.ci-wondering2:before {
  content: "\ea9a";
}
.ci-sleepy:before {
  content: "\ea9b";
}
.ci-sleepy2:before {
  content: "\ea9c";
}
.ci-frustrated:before {
  content: "\ea9d";
}
.ci-frustrated2:before {
  content: "\ea9e";
}
.ci-crying:before {
  content: "\ea9f";
}
.ci-crying2:before {
  content: "\eaa0";
}
.ci-point-up:before {
  content: "\eaa1";
}
.ci-point-right:before {
  content: "\eaa2";
}
.ci-point-down:before {
  content: "\eaa3";
}
.ci-point-left:before {
  content: "\eaa4";
}
.ci-warning:before {
  content: "\eaa5";
}
.ci-notification:before {
  content: "\eaa6";
}
.ci-question:before {
  content: "\eaa7";
}
.ci-plus:before {
  content: "\eaa8";
}
.ci-minus:before {
  content: "\eaa9";
}
.ci-info:before {
  content: "\eaaa";
}
.ci-cancel-circle:before {
  content: "\eaab";
}
.ci-blocked:before {
  content: "\eaac";
}
.ci-cross:before {
  content: "\eaad";
}
.ci-checkmark:before {
  content: "\eaae";
}
.ci-checkmark2:before {
  content: "\eaaf";
}
.ci-spell-check:before {
  content: "\eab0";
}
.ci-enter:before {
  content: "\eab1";
}
.ci-exit:before {
  content: "\eab2";
}
.ci-play2:before {
  content: "\eab3";
}
.ci-pause:before {
  content: "\eab4";
}
.ci-stop:before {
  content: "\eab5";
}
.ci-previous:before {
  content: "\eab6";
}
.ci-next:before {
  content: "\eab7";
}
.ci-backward:before {
  content: "\eab8";
}
.ci-forward2:before {
  content: "\eab9";
}
.ci-play3:before {
  content: "\eaba";
}
.ci-pause2:before {
  content: "\eabb";
}
.ci-stop2:before {
  content: "\eabc";
}
.ci-backward2:before {
  content: "\eabd";
}
.ci-forward3:before {
  content: "\eabe";
}
.ci-first:before {
  content: "\eabf";
}
.ci-last:before {
  content: "\eac0";
}
.ci-previous2:before {
  content: "\eac1";
}
.ci-next2:before {
  content: "\eac2";
}
.ci-eject:before {
  content: "\eac3";
}
.ci-volume-high:before {
  content: "\eac4";
}
.ci-volume-medium:before {
  content: "\eac5";
}
.ci-volume-low:before {
  content: "\eac6";
}
.ci-volume-mute:before {
  content: "\eac7";
}
.ci-volume-mute2:before {
  content: "\eac8";
}
.ci-volume-increase:before {
  content: "\eac9";
}
.ci-volume-decrease:before {
  content: "\eaca";
}
.ci-loop:before {
  content: "\eacb";
}
.ci-loop2:before {
  content: "\eacc";
}
.ci-infinite:before {
  content: "\eacd";
}
.ci-shuffle:before {
  content: "\eace";
}
.ci-arrow-up-left:before {
  content: "\eacf";
}
.ci-arrow-up:before {
  content: "\ead0";
}
.ci-arrow-up-right:before {
  content: "\ead1";
}
.ci-arrow-right:before {
  content: "\ead2";
}
.ci-arrow-down-right:before {
  content: "\ead3";
}
.ci-arrow-down:before {
  content: "\ead4";
}
.ci-arrow-down-left:before {
  content: "\ead5";
}
.ci-arrow-left:before {
  content: "\ead6";
}
.ci-arrow-up-left2:before {
  content: "\ead7";
}
.ci-arrow-up2:before {
  content: "\ead8";
}
.ci-arrow-up-right2:before {
  content: "\ead9";
}
.ci-arrow-right2:before {
  content: "\eada";
}
.ci-arrow-down-right2:before {
  content: "\eadb";
}
.ci-arrow-down2:before {
  content: "\eadc";
}
.ci-arrow-down-left2:before {
  content: "\eadd";
}
.ci-arrow-left2:before {
  content: "\eade";
}
.ci-circle-up:before {
  content: "\eadf";
}
.ci-circle-right:before {
  content: "\eae0";
}
.ci-circle-down:before {
  content: "\eae1";
}
.ci-circle-left:before {
  content: "\eae2";
}
.ci-tab:before {
  content: "\eae3";
}
.ci-move-up:before {
  content: "\eae4";
}
.ci-move-down:before {
  content: "\eae5";
}
.ci-sort-alpha-asc:before {
  content: "\eae6";
}
.ci-sort-alpha-desc:before {
  content: "\eae7";
}
.ci-sort-numeric-asc:before {
  content: "\eae8";
}
.ci-sort-numberic-desc:before {
  content: "\eae9";
}
.ci-sort-amount-asc:before {
  content: "\eaea";
}
.ci-sort-amount-desc:before {
  content: "\eaeb";
}
.ci-command:before {
  content: "\eaec";
}
.ci-shift:before {
  content: "\eaed";
}
.ci-ctrl:before {
  content: "\eaee";
}
.ci-opt:before {
  content: "\eaef";
}
.ci-checkbox-checked:before {
  content: "\eaf0";
}
.ci-checkbox-unchecked:before {
  content: "\eaf1";
}
.ci-radio-checked:before {
  content: "\eaf2";
}
.ci-radio-checked2:before {
  content: "\eaf3";
}
.ci-radio-unchecked:before {
  content: "\eaf4";
}
.ci-crop:before {
  content: "\eaf5";
}
.ci-make-group:before {
  content: "\eaf6";
}
.ci-ungroup:before {
  content: "\eaf7";
}
.ci-scissors:before {
  content: "\eaf8";
}
.ci-filter:before {
  content: "\eaf9";
}
.ci-font:before {
  content: "\eafa";
}
.ci-ligature:before {
  content: "\eafb";
}
.ci-ligature2:before {
  content: "\eafc";
}
.ci-text-height:before {
  content: "\eafd";
}
.ci-text-width:before {
  content: "\eafe";
}
.ci-font-size:before {
  content: "\eaff";
}
.ci-bold:before {
  content: "\eb00";
}
.ci-underline:before {
  content: "\eb01";
}
.ci-italic:before {
  content: "\eb02";
}
.ci-strikethrough:before {
  content: "\eb03";
}
.ci-omega:before {
  content: "\eb04";
}
.ci-sigma:before {
  content: "\eb05";
}
.ci-page-break:before {
  content: "\eb06";
}
.ci-superscript:before {
  content: "\eb07";
}
.ci-subscript:before {
  content: "\eb08";
}
.ci-superscript2:before {
  content: "\eb09";
}
.ci-subscript2:before {
  content: "\eb0a";
}
.ci-text-color:before {
  content: "\eb0b";
}
.ci-pagebreak:before {
  content: "\eb0c";
}
.ci-clear-formatting:before {
  content: "\eb0d";
}
.ci-table:before {
  content: "\eb0e";
}
.ci-table2:before {
  content: "\eb0f";
}
.ci-insert-template:before {
  content: "\eb10";
}
.ci-pilcrow:before {
  content: "\eb11";
}
.ci-ltr:before {
  content: "\eb12";
}
.ci-rtl:before {
  content: "\eb13";
}
.ci-section:before {
  content: "\eb14";
}
.ci-paragraph-left:before {
  content: "\eb15";
}
.ci-paragraph-center:before {
  content: "\eb16";
}
.ci-paragraph-right:before {
  content: "\eb17";
}
.ci-paragraph-justify:before {
  content: "\eb18";
}
.ci-indent-increase:before {
  content: "\eb19";
}
.ci-indent-decrease:before {
  content: "\eb1a";
}
.ci-share:before {
  content: "\eb1b";
}
.ci-new-tab:before {
  content: "\eb1c";
}
.ci-embed:before {
  content: "\eb1d";
}
.ci-embed2:before {
  content: "\eb1e";
}
.ci-terminal:before {
  content: "\eb1f";
}
.ci-share2:before {
  content: "\eb20";
}
.ci-mail:before {
  content: "\eb21";
}
.ci-mail2:before {
  content: "\eb22";
}
.ci-mail3:before {
  content: "\eb23";
}
.ci-mail4:before {
  content: "\eb24";
}
.ci-amazon:before {
  content: "\eb25";
}
.ci-google:before {
  content: "\eb26";
}
.ci-google2:before {
  content: "\eb27";
}
.ci-google3:before {
  content: "\eb28";
}
.ci-google-plus:before {
  content: "\eb29";
}
.ci-google-plus2:before {
  content: "\eb2a";
}
.ci-google-plus3:before {
  content: "\eb2b";
}
.ci-hangouts:before {
  content: "\eb2c";
}
.ci-google-drive:before {
  content: "\eb2d";
}
.ci-facebook:before {
  content: "\eb2e";
}
.ci-facebook2:before {
  content: "\eb2f";
}
.ci-instagram:before {
  content: "\eb30";
}
.ci-whatsapp:before {
  content: "\eb31";
}
.ci-spotify:before {
  content: "\eb32";
}
.ci-telegram:before {
  content: "\eb33";
}
.ci-twitter:before {
  content: "\eb34";
}
.ci-vine:before {
  content: "\eb35";
}
.ci-vk:before {
  content: "\eb36";
}
.ci-renren:before {
  content: "\eb37";
}
.ci-sina-weibo:before {
  content: "\eb38";
}
.ci-rss:before {
  content: "\eb39";
}
.ci-rss2:before {
  content: "\eb3a";
}
.ci-youtube:before {
  content: "\eb3b";
}
.ci-youtube2:before {
  content: "\eb3c";
}
.ci-twitch:before {
  content: "\eb3d";
}
.ci-vimeo:before {
  content: "\eb3e";
}
.ci-vimeo2:before {
  content: "\eb3f";
}
.ci-lanyrd:before {
  content: "\eb40";
}
.ci-flickr:before {
  content: "\eb41";
}
.ci-flickr2:before {
  content: "\eb42";
}
.ci-flickr3:before {
  content: "\eb43";
}
.ci-flickr4:before {
  content: "\eb44";
}
.ci-dribbble:before {
  content: "\eb45";
}
.ci-behance:before {
  content: "\eb46";
}
.ci-behance2:before {
  content: "\eb47";
}
.ci-deviantart:before {
  content: "\eb48";
}
.ci-500px:before {
  content: "\eb49";
}
.ci-steam:before {
  content: "\eb4a";
}
.ci-steam2:before {
  content: "\eb4b";
}
.ci-dropbox:before {
  content: "\eb4c";
}
.ci-onedrive:before {
  content: "\eb4d";
}
.ci-github:before {
  content: "\eb4e";
}
.ci-npm:before {
  content: "\eb4f";
}
.ci-basecamp:before {
  content: "\eb50";
}
.ci-trello:before {
  content: "\eb51";
}
.ci-wordpress:before {
  content: "\eb52";
}
.ci-joomla:before {
  content: "\eb53";
}
.ci-ello:before {
  content: "\eb54";
}
.ci-blogger:before {
  content: "\eb55";
}
.ci-blogger2:before {
  content: "\eb56";
}
.ci-tumblr:before {
  content: "\eb57";
}
.ci-tumblr2:before {
  content: "\eb58";
}
.ci-yahoo:before {
  content: "\eb59";
}
.ci-yahoo2:before {
  content: "\eb5a";
}
.ci-tux:before {
  content: "\eb5b";
}
.ci-appleinc:before {
  content: "\eb5c";
}
.ci-finder:before {
  content: "\eb5d";
}
.ci-android:before {
  content: "\eb5e";
}
.ci-windows:before {
  content: "\eb5f";
}
.ci-windows8:before {
  content: "\eb60";
}
.ci-soundcloud:before {
  content: "\eb61";
}
.ci-soundcloud2:before {
  content: "\eb62";
}
.ci-skype:before {
  content: "\eb63";
}
.ci-reddit:before {
  content: "\eb64";
}
.ci-hackernews:before {
  content: "\eb65";
}
.ci-wikipedia:before {
  content: "\eb66";
}
.ci-linkedin:before {
  content: "\eb67";
}
.ci-linkedin2:before {
  content: "\eb68";
}
.ci-lastfm:before {
  content: "\eb69";
}
.ci-lastfm2:before {
  content: "\eb6a";
}
.ci-delicious:before {
  content: "\eb6b";
}
.ci-stumbleupon:before {
  content: "\eb6c";
}
.ci-stumbleupon2:before {
  content: "\eb6d";
}
.ci-stackoverflow:before {
  content: "\eb6e";
}
.ci-pinterest:before {
  content: "\eb6f";
}
.ci-pinterest2:before {
  content: "\eb70";
}
.ci-xing:before {
  content: "\eb71";
}
.ci-xing2:before {
  content: "\eb72";
}
.ci-flattr:before {
  content: "\eb73";
}
.ci-foursquare:before {
  content: "\eb74";
}
.ci-yelp:before {
  content: "\eb75";
}
.ci-paypal:before {
  content: "\eb76";
}
.ci-chrome:before {
  content: "\eb77";
}
.ci-firefox:before {
  content: "\eb78";
}
.ci-IE:before {
  content: "\eb79";
}
.ci-edge:before {
  content: "\eb7a";
}
.ci-safari:before {
  content: "\eb7b";
}
.ci-opera:before {
  content: "\eb7c";
}
.ci-file-pdf:before {
  content: "\eb7d";
}
.ci-file-openoffice:before {
  content: "\eb7e";
}
.ci-file-word:before {
  content: "\eb7f";
}
.ci-file-excel:before {
  content: "\eb80";
}
.ci-libreoffice:before {
  content: "\eb81";
}
.ci-html-five:before {
  content: "\eb82";
}
.ci-html-five2:before {
  content: "\eb83";
}
.ci-css3:before {
  content: "\eb84";
}
.ci-git:before {
  content: "\eb85";
}
.ci-codepen:before {
  content: "\eb86";
}
.ci-svg:before {
  content: "\eb87";
}
.ci-IcoMoon:before {
  content: "\eb88";
}
.ci-pen-vector:before {
  content: "\e91e";
}
.ci-speaker:before {
  content: "\e91f";
}
.ci-screen:before {
  content: "\e93b";
}
.ci-ok:before {
  content: "\eb89";
}
.ci-flag1:before {
  content: "\eb8a";
}
.ci-unauthorized-user:before {
  content: "\e96b";
}
.ci-side-menu-bg:before {
  content: "\e969";
}
.ci-side-menu-bg-rtl:before {
  content: "\e96a";
}
.ci-readiness-empty:before {
  content: "\e968";
}
.ci-survey-empty:before {
  content: "\e967";
}
.ci-recent-activity-empty:before {
  content: "\e958";
}
.ci-reports-empty:before {
  content: "\e959";
}
.ci-settings-empty:before {
  content: "\e95a";
}
.ci-visits-empty:before {
  content: "\e95b";
}
.ci-issues-empty:before {
  content: "\e95c";
}
.ci-locations-empty:before {
  content: "\e95d";
}
.ci-no-data-empty:before {
  content: "\e95e";
}
.ci-notification-empty:before {
  content: "\e95f";
}
.ci-points-empty:before {
  content: "\e960";
}
.ci-profile1:before {
  content: "\e961";
}
.ci-documents-empty:before {
  content: "\e962";
}
.ci-dummy-image:before {
  content: "\e963";
}
.ci-actions-empty:before {
  content: "\e964";
}
.ci-activities-empty:before {
  content: "\e965";
}
.ci-avatar:before {
  content: "\e966";
}
.ci-question1:before {
  content: "\e957";
}
.ci-earth1:before {
  content: "\e955";
}
.ci-people:before {
  content: "\e956";
}
.ci-room-na:before {
  content: "\e953";
}
.ci-room:before {
  content: "\e954";
}
.ci-escort:before {
  content: "\e952";
}
.ci-airplan:before {
  content: "\e949";
}
.ci-arrival:before {
  content: "\e94a";
}
.ci-bus:before {
  content: "\e94b";
}
.ci-depart:before {
  content: "\e94c";
}
.ci-excel:before {
  content: "\e94d";
}
.ci-family:before {
  content: "\e94e";
}
.ci-hotel:before {
  content: "\e94f";
}
.ci-person:before {
  content: "\e950";
}
.ci-wheelchair:before {
  content: "\e951";
}
.ci-due-actions:before {
  content: "\e947";
}
.ci-all-actions:before {
  content: "\e948";
}
.ci-enlarge1:before {
  content: "\e945";
}
.ci-shrink1:before {
  content: "\e946";
}
.ci-portal:before {
  content: "\e944";
}
.ci-angry1:before {
  content: "\e93d";
}
.ci-happy1:before {
  content: "\e93e";
}
.ci-neutral1:before {
  content: "\e93f";
}
.ci-sad1:before {
  content: "\e940";
}
.ci-smile1:before {
  content: "\e941";
}
.ci-star-empty1:before {
  content: "\e942";
}
.ci-star-full1:before {
  content: "\e943";
}
.ci-action:before {
  content: "\e93c";
}
.ci-map1:before {
  content: "\e93a";
}
.ci-grid-view:before {
  content: "\e936";
}
.ci-list-view:before {
  content: "\e937";
}
.ci-sort-indicator:before {
  content: "\e938";
}
.ci-sort-indicator-desc:before {
  content: "\e939";
}
.ci-logout:before {
  content: "\e935";
}
.ci-mask:before {
  content: "\e934";
}
.ci-error:before {
  content: "\e933";
}
.ci-issue:before {
  content: "\e932";
}
.ci-home1:before {
  content: "\e931";
}
.ci-arrow-left1:before {
  content: "\e92c";
}
.ci-arrow-right1:before {
  content: "\e92d";
}
.ci-actions:before {
  content: "\e900";
}
.ci-add:before {
  content: "\e901";
}
.ci-arrow-down1:before {
  content: "\e902";
}
.ci-arrow-up1:before {
  content: "\e903";
}
.ci-attachment1:before {
  content: "\e904";
}
.ci-calender:before {
  content: "\e905";
}
.ci-camera1:before {
  content: "\e906";
}
.ci-checked:before {
  content: "\e907";
}
.ci-check-mark:before {
  content: "\e908";
}
.ci-close:before {
  content: "\e909";
}
.ci-dashboard:before {
  content: "\e90a";
}
.ci-doc:before {
  content: "\e90b";
}
.ci-download1:before {
  content: "\e90c";
}
.ci-expand1:before {
  content: "\e90d";
}
.ci-files:before {
  content: "\e90e";
}
.ci-envelope:before {
  content: "\e90f";
}
.ci-image1:before {
  content: "\e910";
}
.ci-inspector:before {
  content: "\e911";
}
.ci-job:before {
  content: "\e912";
}
.ci-loading:before {
  content: "\e913";
}
.ci-location1:before {
  content: "\e914";
}
.ci-manager:before {
  content: "\e915";
}
.ci-menu1:before {
  content: "\e916";
}
.ci-more:before {
  content: "\e917";
}
.ci-naviagte:before {
  content: "\e918";
}
.ci-note:before {
  content: "\e919";
}
.ci-notification1:before {
  content: "\e91a";
}
.ci-notifications:before {
  content: "\e91b";
}
.ci-phone1:before {
  content: "\e91c";
}
.ci-points:before {
  content: "\e91d";
}
.ci-remove:before {
  content: "\e920";
}
.ci-reports:before {
  content: "\e921";
}
.ci-schedule-date:before {
  content: "\e922";
}
.ci-search1:before {
  content: "\e923";
}
.ci-sort:before {
  content: "\e924";
}
.ci-success:before {
  content: "\e925";
}
.ci-time:before {
  content: "\e926";
}
.ci-unchecked:before {
  content: "\e927";
}
.ci-upload1:before {
  content: "\e928";
}
.ci-user1:before {
  content: "\e929";
}
.ci-view:before {
  content: "\e92a";
}
.ci-violation:before {
  content: "\e92b";
}
.ci-sort-down:before {
  content: "\e92e";
}
.ci-sort-up:before {
  content: "\e92f";
}
.ci-units:before {
  content: "\e930";
}
.ci-primary-Info:before {
  content: "\e96c";
}
.ci-sites-icon:before {
  content: "\e96d";
}
.ci-site:before {
  content: "\e96e";
}
.ci-assets-icon:before {
  content: "\e96f";
}
.ci-asset:before {
  content: "\e973";
}
.ci-users-icon:before {
  content: "\e970";
}
.ci-user11:before {
  content: "\e974";
}
.ci-group-users:before {
  content: "\e975";
}
.ci-edit:before {
  content: "\e976";
}
.ci-steps-done:before {
  content: "\e977";
}
.ci-back:before {
  content: "\e978";
}
.ci-progress:before {
  content: "\e971";
}
.ci-expand-down:before {
  content: "\e972";
}
.ci-expand-up:before {
  content: "\e979";
}
.ci-sites-assets:before {
  content: "\e97a";
}
.ci-information-circle:before {
  content: "\e97b";
}
.ci-server-error:before {
  content: "\e97c";
}
.ci-link-survey:before {
  content: "\e97d";
}
.ci-email-survey:before {
  content: "\e97e";
}
.ci-new-download:before {
  content: "\e97f";
}
.ci-conform-issue:before {
  content: "\e980";
}
.ci-files-icon-pdf:before {
  content: "\e981";
}
.ci-files-icon-docx:before {
  content: "\e982";
}
.ci-files-icon-ppt:before {
  content: "\e983";
}
.ci-files-icon-xls:before {
  content: "\e984";
}
.ci-files-icon-picture:before {
  content: "\e985";
}
.ci-files-icon-other:before {
  content: "\e986";
}
.ci-menu-language:before {
  content: "\e987";
  color: #778ca2;
}
.ci-menu-logout:before {
  content: "\e988";
  color: #778ca2;
}
.ci-menu-notification:before {
  content: "\e989";
  color: #778ca2;
}
.ci-menu-password:before {
  content: "\e98a";
  color: #778ca2;
}
.ci-menu-portal:before {
  content: "\e98b";
  color: #778ca2;
}
.ci-menu-profile:before {
  content: "\e98c";
  color: #778ca2;
}
.ci-menu-setting:before {
  content: "\e98d";
  color: #778ca2;
}
.ci-menu-main-dropdown:before {
  content: "\e98e";
}
.ci-menu-main-notification:before {
  content: "\e98f";
}
.ci-menu-main-plus:before {
  content: "\e990";
}
.ci-mimize:before {
  content: "\e991";
}
.ci-mic1:before {
  content: "\e992";
}
.ci-unmute:before {
  content: "\e993";
}
.ci-screenshot:before {
  content: "\e994";
}
.ci-video:before {
  content: "\e995";
}
.ci-conference-endcall:before {
  content: "\e996";
}
.ci-conference-swap:before {
  content: "\e997";
}
.ci-conference-user:before {
  content: "\e998";
}
.ci-maximize:before {
  content: "\e999";
}
.ci-maplist:before {
  content: "\e99a";
}
.ci-mapsite:before {
  content: "\e99b";
}
.ci-filter1:before {
  content: "\e99c";
}
.ci-license:before {
  content: "\e99d";
}
