@font-face {
  font-family: "Dubai";
  src: url("../fonts/dubai/Dubai-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Dubai-light";
  src: url("../fonts/dubai/Dubai-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Dubai-med";
  src: url("../fonts/dubai/Dubai-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Dubai-bold";
  src: url("../fonts/dubai/Dubai-Bold.ttf") format("truetype");
}
.me-2 {
  margin-inline-end: 0.5rem;
}

.cursor-pointer {
  cursor: pointer;
}

.unicode-plainText {
  unicode-bidi: plaintext;
}

.word-break {
  word-break: break-word;
}

.rtl.rtl-icon,
.rtl .rtl-icon {
  transform: rotateY(180deg);
}

:root {
  --msn-primary-main: #047AC2;
  --msn-primary-dark: #0B212D;
  --msn-primary-light: #E6F1FF;
  --msn-secondary-main: #29cc8b;
  --msn-secondary-dark: #21BF80;
  --msn-secondary-light: #F1FDF4;
  --msn-white: #fff;
  --msn-grey-main: #4A5A6B;
  --msn-grey-light: #f5f8fa;
  --msn-black: #000;
  --msn-success: #29cc8b;
  --msn-error: #f93d3d;
  --msn-error-light: #FEE2E2;
  --msn-error-lightness-90: #FDCECE;
  --msn-error-lightness-86: #FDBABA;
  --msn-orange: #FDAA3E;
  --msn-orange-light: #FFEBD1;
  --msn-picton-blue: #0065A4;
  --msn-picton-blue-light: #F1FAFF;
  --msn-texas-rose: #FFBC4F;
  --msn-texas-rose-light: #FFF5E5;
  --msn-mauve: #B898FE;
  --msn-mauve-light: #F9F7FF;
  --msn-font-style-main: #173E67;
  --msn-font-style-secondary: #5D758E;
  --msn-font-style-placeholder: #C3C6C9;
  --msn-bg-ice-blue: #F7FBFF;
  --msn-bg-cloud: #FAFBFC;
  --msn-bg-overlay: rgba(0, 0, 0, 0.7);
  --msn-border-color-main: #D0D9E2;
  --msn-border-color-light: #EFF3F6;
  --msn-gradients-green: linear-gradient(237.68deg, #7BE9B8 0%, #52BC8D 100%);
  --msn-gradients-blue-green: linear-gradient(237.68deg, #65D3A1 0.52%, #4BB3EF 53.13%, #6176EF 100%);
  --msn-gradients-blue: linear-gradient(259.27deg, #92BEF8 0%, #5989EF 100%);
  --msn-shadow-main: #0000001a;
  --msn-box-shadow-normal-color: rgba(20, 50, 80, 0.12);
  --msn-box-shadow-normal: box-shadow: 0px 2px 35px 0px var(--msn-shadow-main);
  --xSmall: 5px;
  --small: 10px;
  --medium: 15px;
  --large: 20px;
  --xLarge:30px;
  --xxLarge:50px;
}

body {
  font-family: "Dubai";
  overflow-x: hidden;
}

.text-primary {
  font-family: "Dubai" !important;
}

p {
  color: #4a5a6b;
  font-family: "Dubai-med";
}

a {
  text-decoration: none !important;
}

img.about-sec-img {
  max-width: 100%;
  width: auto;
}

.form-control:focus,
.form-select:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #d1e2f5 !important;
  outline: 0;
  box-shadow: none;
}

.form-control,
.form-select {
  padding-top: 15px;
  padding-bottom: 15px;
  border-color: #cee0ec;
  border-radius: 20px;
}

.btn.btn-primary {
  background-color: var(--msn-primary-main);
  padding: 12px;
  min-width: 150px;
  position: relative;
  cursor: pointer !important;
  border: 0 !important;
  border-radius: 25px;
}
.btn.btn-primary span {
  position: absolute;
  top: calc(50% - 12px);
  inset-inline-end: 12px;
  transform: scaleX(-1);
}
.btn.btn-primary span img {
  width: 15px;
}
.btn.btn-primary:hover, .btn.btn-primary:focus {
  background-color: #015f99 !important;
  border: 0 !important;
  cursor: pointer !important;
}

.text-primary {
  color: var(--msn-picton-blue) !important;
}

.text-secondary {
  color: rgb(255, 242, 0) !important;
}

.fs-16 {
  font-size: 16px;
}

.underline-none {
  text-decoration: none;
}

.iti {
  width: 100% !important;
}

.lang-box {
  text-align: end;
  padding: 10px 20px 0 20px;
}
.lang-box .lang {
  gap: 5px;
  font-size: 14px;
  cursor: pointer !important;
  font-family: "helvetica neue";
  color: #0165a4;
  text-decoration: none;
}
.lang-box .lang span {
  padding-inline-end: 5px;
}

.po-cursor {
  cursor: pointer;
}

.text-yellow {
  color: #fff235 !important;
}

.bg-blue-light {
  background-color: var(--msn-picton-blue);
}

.bg-very-blue-light {
  background-color: #d5dfe4;
}

.shadow-bottom-light {
  box-shadow: rgba(206, 224, 236, 0.22) 0px 10px 15px -12px, rgba(206, 224, 236, 0.09) 0px 30px 18px -14px;
}

.btn-small {
  padding: 5px 9px !important;
  min-width: unset !important;
  border-radius: 11px !important;
}

.p-tabview-nav {
  justify-content: center;
  font-family: "Dubai";
}
.p-tabview-nav .p-tabview-nav-link span {
  color: #0b212d !important;
  padding: 20px 20px 0 20px !important;
  font-family: "Dubai";
}
.p-tabview-nav .p-tabview-nav-link:hover {
  border-bottom: 0;
}
.p-tabview-nav .p-tabview-nav-link:hover span {
  color: #0165a4 !important;
}
.p-tabview-nav .p-highlight .p-tabview-nav-link {
  background: #ffffff;
  border-color: #0165a4;
  border-bottom: 2px solid #0165a4;
}
.p-tabview-nav .p-highlight .p-tabview-nav-link span {
  color: #0165a4 !important;
}

.home-label {
  color: var(--msn-picton-blue);
  font-size: 20px;
  position: relative;
  z-index: 4;
  font-family: "Dubai-med";
}

[dir=rtl] .flip-horizontally {
  transform: scaleX(-1);
}
[dir=rtl] .text-start {
  text-align: right !important;
}
[dir=rtl] .btn.btn-primary span {
  transform: scaleX(-1);
}
[dir=rtl] .about-section .about-sec-img {
  transform: scaleX(-1);
}
[dir=rtl] .form-select {
  padding-right: 0.75rem;
  padding-left: 2.25rem;
  background-position: left 0.75rem center;
}
[dir=rtl] .form-check .form-check-input.accept {
  float: right;
  margin-right: -1.5em;
  margin-left: unset;
}
[dir=rtl] .whyus-title::before {
  content: "";
  right: 30%;
  top: -40px;
}

main[dir=ltr] .btn.btn-primary span img {
  transform: scaleX(-1);
}
main[dir=ltr] .dropdown-menu {
  left: unset;
  right: 0;
}

.modal .modal-header {
  background-color: var(--msn-picton-blue);
  color: #fff;
  position: absolute;
  width: 110%;
  height: 150px;
  top: -80px;
  z-index: -1;
  right: -5%;
  border-radius: 30px;
  align-items: flex-start;
  justify-content: center;
}
.modal .modal-header .btn-close {
  position: absolute;
  top: 20px;
  left: 12px;
  color: #fff;
  --bs-btn-close-bg: url("../images/icons/exit-white.svg");
  --bs-btn-close-opacity: 1;
  background-size: 25px;
}
.modal .modal-header .modal-title {
  margin-top: 10px;
}
.modal .modal-content {
  border-radius: 30px;
  margin-top: 80px;
}

.p-tabview-nav-container {
  margin-top: 20px;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.cursor-pointer {
  cursor: pointer !important;
}
.cursor-pointer:hover {
  cursor: pointer !important;
}

.modal-lg-custom {
  max-width: 50% !important;
}

.preloader svg {
  width: 100%;
  height: auto;
}

.preloader .path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  stroke: black;
  fill-opacity: 0;
  animation: dash 1s ease-in-out forwards, fill 1s ease-in-out forwards 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-duration: 3s;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes fill {
  to {
    fill-opacity: 1;
  }
}
main[dir=rtl] input[type=tel] {
  text-align: right;
  direction: ltr;
}
main[dir=rtl] input[type=tel]:-moz-placeholder {
  text-align: right;
}
main[dir=rtl] input[type=tel]:-ms-input-placeholder {
  text-align: right;
}
main[dir=rtl] input[type=tel]::-webkit-input-placeholder {
  text-align: right;
}

main[dir=rtl] .otp-input[type=tel] {
  text-align: center;
  direction: ltr;
}
main[dir=rtl] .otp-input[type=tel]:-moz-placeholder {
  text-align: center;
}
main[dir=rtl] .otp-input[type=tel]:-ms-input-placeholder {
  text-align: center;
}
main[dir=rtl] .otp-input[type=tel]::-webkit-input-placeholder {
  text-align: center;
}

.cancleBtn {
  color: #047ac2;
}
.cancleBtn:hover {
  cursor: pointer !important;
}

.gray-btn {
  background-color: #dbe3e8 !important;
  color: #949398;
}
.gray-btn:hover {
  color: #fff;
}

.payment-note {
  background-color: #f2f7fa !important;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
}
.payment-note h5 {
  font-size: 18px !important;
}
.payment-note span {
  font-weight: 700;
}

.ltr {
  direction: ltr !important;
}

@media (min-width: 776.98px) {
  .modal.show .modal-dialog {
    max-width: 60%;
    width: max-content;
  }
}
.calendar {
  padding: 0px;
}

.calendar .p-calendar {
  width: 100%;
  padding: 6px;
}

.calendar .p-calendar input {
  border: 0 !important;
}

.calendar .p-calendar input:focus {
  box-shadow: unset !important;
  outline: unset !important;
}